import { createApp } from "vue"
import Routes from "./router/index"
import App from "./App.vue"
import Store from "./store/index"
import "./middleware/index"
//font awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import "./fontawesome"

//global styles
import "./index.css"

//date picker
import { SetupCalendar } from 'v-calendar';


//translate
import i18n from './common/i18n'

const app = createApp(App)

//app filters
app.config.globalProperties.$filters = {
    //translate numbers
    RTLNumbers(value) {
        if(Store.getters.appLanguage=='ar') {
            return String(value).replace(/\d/g, d =>  '٠١٢٣٤٥٦٧٨٩'[d])
        }else {
            return value
        }
    }
}

app.use(Store)
    .use(Routes)
    .use(i18n)
    .use(SetupCalendar, {})
    .component("font-awesome-icon", FontAwesomeIcon)
    .mount("#app")
