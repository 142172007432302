import Request from "@/common/Request"

export default {
    state: {
        notificationsList: [],
    },
    mutations: {
        setNotificationsList(state, newData) {
            state.notificationsList = newData;
        },
    },
    actions: {
        async updateNotificationsList(context, payload) {
            await new Request().get(`notifications`).then((res) => {
                if (res) {
                    context.commit("setNotificationsList", res.data.data)
                }
            }).catch((err) => {
                // isLoading.value = false
            })
        },
    },
    getters: {
        notificationsList(state) {
            return state.notificationsList
        },
        unReadNotifications(state) {
            let unread_notifications = []
            state.notificationsList?.forEach(notification => {
                if(notification.read_at == null) {
                    unread_notifications.push(notification)
                }
            })
            return unread_notifications || []
        },
    },
} 