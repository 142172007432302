import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faCube,
    faEquals,
    faPlus,
    faMinus,
    faInfo,
    faTrashAlt,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faXmark,
    faEyeSlash

} from "@fortawesome/free-solid-svg-icons";

import {
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faTwitter,
    faApple,
    faGoogle,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faCube,
    faEquals,
    faPlus,
    faMinus,
    faInfo,
    faTrashAlt,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faChevronUp,
    faFacebookF,
    faLinkedinIn,
    faTwitter,
    faInstagram,
    faApple,
    faGoogle,
    faXmark,
    faEyeSlash
);
