export default class Routes {
    static HOME_ROUTE = { name: "home" }
    static CATEGORIES_PAGE_ROUTE = { name: "categories" }
    static CATEGORY_PAGE_ROUTE = { name: "category" }
    static PRODUCT_DETAILS = { name: "product" }
    static FEATURED_PRODUCTS = { name: "featured-products" }
    static BLOGS_ROUTE = { name: "blogs" }
    static BLOG_INFO_ROUTE = { name: "blog-info" }
    static WISHLIST = { name: "wishlist" }
    static ABOUT_US_ROUTE = { name: "about-us" }

    //notifications
    static NOTIFICATIONS_PAGE = { name: "notifications-center" }

    // Payment PAGES
    static VIEW_CART = { name: "view-cart" }
    static CHECKOUT_PAGE = { name: "checkout" }
    static ORDER_RECEIVED = { name: "order-received" }
    
    //search page
    static SEARCH_ROUTE = { name: "search" }

    //setting pages
    static SETTING = { name: "setting" }
    
    static CHARGE_WALLET = { name: "charge-wallet" }
    static CHANGE_PASSWORD = { name: "change-password" }
    static EDIT_ADDRESS = { name: "edit-address" }
    static ACCOUNT_SETTING = { name: "account-setting" }
    static TRACK_ORDER = { name: "track-order" }
    static ORDERS_HISTORY = { name: "orders-history" }
    static RATE_ORDER = { name: "rate-order" }

    static RESET_PASS_ROUTE = { name: "reset-password" }
    static PAGES_ROUTE = { name: "pages" }
    
}
