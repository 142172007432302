import { createRouter, createWebHistory } from 'vue-router'
import Routes from "@/common/Routes"


const routes = [

  {
    path: "/",
    component: () => import('@/components/pages/LandingLayout.vue'),
    children: [
      {
        path: "",
        name: Routes.HOME_ROUTE.name,
        component: () => import('@/components/pages/Landing.vue')

      },
      {
        path: "categories",
        name: Routes.CATEGORIES_PAGE_ROUTE.name,
        component: () => import('@/components/pages/CategoriesList.vue')
      },
      {
        path: "categories/:CatSlug/sub-categories/:subCatSlug?",
        name: Routes.CATEGORY_PAGE_ROUTE.name,
        component: () => import('@/components/pages/CategoryDetails.vue')
      },
      {
        path: "product/:productSlug",
        name: Routes.PRODUCT_DETAILS.name,
        component: () => import('@/components/pages/ProductDetails.vue')
      },
      {
        path: "featured-products",
        name: Routes.FEATURED_PRODUCTS.name,
        component: () => import('@/components/pages/FeaturedProducts.vue')
      },
      {
        path: "blogs",
        name: Routes.BLOGS_ROUTE.name,
        component: () => import('@/components/pages/Blogs.vue')
      },
      {
        path: "blogs/:id",
        name: Routes.BLOG_INFO_ROUTE.name,
        component: () => import('@/components/pages/BlogDetails.vue')
      },
      {
        path: "view-cart",
        name: Routes.VIEW_CART.name,
        component: () => import('@/components/pages/payment-pages/ViewCart.vue')
      },
      {
        path: "notifications-center",
        name: Routes.NOTIFICATIONS_PAGE.name,
        component: () => import('@/components/pages/Notifications.vue'),
        meta: { requireAuth: true },

      },
      {
        path: "checkout",
        name: Routes.CHECKOUT_PAGE.name,
        component: () => import('@/components/pages/payment-pages/Checkout.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "checkout/order-received",
        name: Routes.ORDER_RECEIVED.name,
        component: () => import('@/components/pages/payment-pages/OrderReceived.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "search",
        name: Routes.SEARCH_ROUTE.name,
        component: () => import('@/components/pages/Search.vue')
      },
      {
        path: "wishlist",
        name: Routes.WISHLIST.name,
        component: () => import('@/components/pages/WishList.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "setting",
        name: Routes.SETTING.name,
        component: () => import('@/components/pages/setting-pages/Setting.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "setting/charge-wallet",
        name: Routes.CHARGE_WALLET.name,
        component: () => import('@/components/pages/setting-pages/ChargeWallet.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "setting/change-password",
        name: Routes.CHANGE_PASSWORD.name,
        component: () => import('@/components/pages/setting-pages/ChangePassword.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "setting/edit-address",
        name: Routes.EDIT_ADDRESS.name,
        component: () => import('@/components/pages/setting-pages/EditAddress.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "setting/account-setting",
        name: Routes.ACCOUNT_SETTING.name,
        component: () => import('@/components/pages/setting-pages/AccountSetting.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "setting/track-order",
        name: Routes.TRACK_ORDER.name,
        component: () => import('@/components/pages/setting-pages/TrackOrder.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "setting/orders-history",
        name: Routes.ORDERS_HISTORY.name,
        component: () => import('@/components/pages/setting-pages/OrderHistory.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "setting/orders-history/rate-order/:id",
        name: Routes.RATE_ORDER.name,
        component: () => import('@/components/pages/setting-pages/RateOrder.vue'),
        meta: { requireAuth: true },
      },
      {
        path: "about-us",
        name: Routes.ABOUT_US_ROUTE.name,
        component: () => import('@/components/pages/AboutUs.vue')
      },
      {
        path: "pages/:id",
        name: Routes.PAGES_ROUTE.name,
        component: () => import('@/components/pages/Pages.vue')
      },
      {
        path: "/reset-password",
        name: Routes.RESET_PASS_ROUTE.name,
        component: () => import('@/components/pages/ResetPassword.vue'),
      },
    ],
  },

]



const router = createRouter({
  history: createWebHistory(''), 
  routes,
  linkActiveClass: 'active-link',
  linkExactActiveClass: 'exact-active-link',
})

export default router
