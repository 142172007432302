import { API_URL } from "../config/request"
import Response from "./Response"
import Store from "../store/index"
import Axios from 'axios';
import router from '@/router/index'

export default class Request {
    constructor() {
        this.store = Store
        Axios.defaults.headers.common['locale'] = this.store.getters.appLanguage;
        
        //handling request headers
        if(this.store.getters.token) {
            Axios.defaults.headers.common['Authorization'] = "Bearer " + this.store.getters.token;
        }else {
            delete Axios.defaults.headers.common["Authorization"];
        }

        if(this.store.getters.userArea?.id) {
            Axios.defaults.headers.common['area'] = this.store.getters.userArea?.id;
        }else {
            delete Axios.defaults.headers.common["area"];
        }
    }

    async post(endpoint, data, queryParams) {
        try {
            const response = await Axios({
                method: 'post',
                responseType: 'json',
                url: this.url(endpoint),
                headers: {
                    "Content-Type": "application/json",
                },
                params: queryParams ? queryParams : {},
                data: JSON.stringify(data)
            })


            return response
        } catch (exception) {
            return new Response(exception).displayErrors()
        }
    }

    async put(endpoint, data) {
        try {
            const response = await Axios({
                method: 'put',
                responseType: 'json',
                url: this.url(endpoint),
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(data)
            })


            return response
        } catch (exception) {
            return new Response(exception).displayErrors()
        }
    }
    
    async patch(endpoint, data) {
        try {
            const response = await Axios({
                method: 'patch',
                responseType: 'json',
                url: this.url(endpoint),
                headers: {
                    "Content-Type": "application/json",
                    area: this.store.getters.userArea?.id || null,
                },
                data: JSON.stringify(data)
            })


            return response
        } catch (exception) {
            return new Response(exception).displayErrors()
        }
    }

    async get(endpoint, page) {
        try {
            let newndpoint;
            if (page) {
                newndpoint = endpoint + "?page=" + page
            }

            const response = await Axios({
                method: 'get',
                url: this.url(newndpoint ? newndpoint : endpoint),
                headers: {
                    "Content-Type": "application/json",
                }
            })
            return response
        } catch (exception) {
            return new Response(exception).displayErrors()
        }
    }

    async getWithfilter(endpoint, params) {
      
        try {  
            const response = await Axios({
                method: 'get',
                url: this.url(endpoint),
                params: params,
                headers: {
                    "Content-Type": "application/json",
                }
            })


            return response
        } catch (exception) {
            return new Response(exception).displayErrors()
        }
    }

    async delete(endpoint, payload, queryParams) {
        try {
            const response = await Axios({
                method: 'delete',
                url: this.url(endpoint),
                headers: {
                    "Content-Type": "application/json",
                },
                data: payload ? payload : {},
                params: queryParams ? queryParams : {},
            })
            return response
        } catch (exception) {
            return new Response(exception).displayErrors()
        }
    }

    url(endpoint) {
        // remove slash if added by developer, will be added later for consistency
        return API_URL + endpoint
    }
}
