import router from "@/router/index"
import Store from "@/store/index"
import Routes from "@/common/Routes"

router.beforeEach(function (to, _, next) {
    if (to.meta.requireAuth && !Store.getters.isLoggedIn) {
        next(Routes.HOME_ROUTE)
    } else next()
})


//checkout guard
router.beforeEach(function (to, _, next) {
    if (to.name == 'checkout'&& (Store.getters.cartProducts.length == 0 || Store.getters.cartOrder?.total_after_discount < Store.getters.cartOrder?.min_amount))  {
        router.replace({path:'/view-cart'})
    }else {
        next()
    }
})

//reset password page guard 

router.beforeEach((to, from, next) => {  
    if (to.name == 'reset-password' && !to.query.email && !to.query.forget_code ) {
        router.replace({path:'/'})
    } else {
        next();
    }
  });