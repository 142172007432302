import CartStore from "@/store/CartStore"

export default {
    state: {
        search_keyword: sessionStorage.getItem('keyword') || '' ,
        language: localStorage.getItem('lang')? localStorage.getItem('lang') : 'en' 
    },
    mutations: {
        setSearchKeyword(state, newKeyword) {
            state.search_keyword = newKeyword
        },
        setLanguage(state, lang) {
            localStorage.setItem('lang', lang)
        }
    },
    actions: {

    },
    getters: {
        searchKeyWord(state) {
            return state.search_keyword
        },
        appLanguage(state) {
            return state.language
        }
    },
    modules: [CartStore],
} 