
export default {
    state: {
        cartOrder: localStorage.getItem('cart_order') != 'undefined'? JSON.parse(localStorage.getItem('cart_order')) : {},
    },
    mutations: {
        setCartOrder(state, newData) {
            state.cartOrder = newData;
        },
    },
    actions: {
        updateCartOrder(context, payload) {
            context.commit("setCartOrder", payload)
        },
    },
    getters: {
        cartOrder(state) {
            return state.cartOrder
        },
        cartTotal: state => {
            return state.cartOrder?.total?.toFixed(2);
        },
        cartProducts(state) {
            let products = []
            state.cartOrder?.categories?.map((category) => {
                products.push(...category.products)
            })
            return Array.from(products) || []
        },
    },
} 