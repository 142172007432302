import axios from 'axios'
import { useToast } from "vue-toastification"
import store from '@/store'
import router from "@/router";
import i18n from '@/common/i18n'

import useEventsBus from '../common/eventBus';
const { emit } = useEventsBus()

const { t } = i18n.global

const toast = useToast()

// apply interceptor on response
axios.interceptors.response.use(function (response) {

  return response
}, function (error) {
  // if has response show the error
  if (error.response) {

    // user area
    if (error.response.data.code === 1) {
      store.commit('selectAreaModal', true)
    }

    // blocked user
    if (error.response.data.code === 3) {
      toast.error(t('messages.user_blocked'))
      router.push('/')
    }

    // UnAuthenticated user
    if (error.response.status === 401 && !error.response?.data?.code) {
      emit('showLogin', true)
    }
    //attempts requests
    if (error.response.status === 429) {
      return
    }

  }
  return Promise.reject(error)
})