import { useToast } from "vue-toastification"
import store from '@/store'
import router from '@/router/index'

export default class Response {
    constructor(data) {
        this.data = data
    }

    data() {
        return this.data
    }

    getErrors() {
        let messages = []

        if(this.data) {
            const errors = this.data?.response?.data?.errors
            for (const key in errors) {
                if (Object.hasOwnProperty.call(errors, key)) {
                    const errorsMessages = errors[key]
                    errorsMessages.forEach((error) => {
                        if (this?.data?.response?.status == 422) {
                            //inputs validations errors
                            messages.push(key + ' ' + error)
                        } else {
                            messages.push(error)
                        }
                    })
                }
            }
        }
        return messages
    }

    displayErrors() {
        const toast = useToast()

        //check if user blocked
        if (this?.data?.response?.status == 400) {
            if (this.data?.response?.data?.code == 3) {
                return            
            }  else {
                toast.error(this?.data?.response?.data?.message)
            } 
            return
        }

        // un authorized error
        if (this?.data?.response?.status == 401) {
            // if there are not selected area
            if (this.data?.response?.data?.code == 1) {
                localStorage.removeItem('userArea')
                store.commit('updateUserArea', null)
                store.commit('selectAreaModal', true)
            } 
            // if account forced to reset password
            else  if (this.data?.response?.data?.code == 2) {
                let userEmailOrPhone = JSON.parse(JSON.stringify(localStorage.getItem('email_or_phone')))
                if(userEmailOrPhone) {
                    router.replace({ path: '/reset-password', query: {'email': userEmailOrPhone} })
                }else {
                    toast.error(this?.data?.response.data.message)
                }
            } else {
                toast.error(this?.data?.response?.data?.message)
            }

            return
        }

        //server error 
        if (this?.data?.response?.data) {
            if (this.data?.response?.data?.code == 1) {
                localStorage.removeItem('userArea')
                store.commit('updateUserArea', null)
                store.commit('selectAreaModal', true)
            } else {
                if (this?.data?.response?.data?.errors) {
                    // check if user not blocked
                    this.getErrors().forEach((error) => {
                        toast.error(error)
                    })
                } else {
                    toast.error(this.data?.message)
                }
            }
        } else {
            //client side error
            if (this?.data?.message) {
                // throw.error(this.data.message)
                throw `${this.data?.message}`
            }
        }

    }

    displaySuccess() {
        const toast = useToast()
        toast.success(this.data.message)
    }
}
