import Request from "@/common/Request"
import Response from "@/common/Response"
import router from '@/router/index'
import Routes from "@/common/Routes"

export default {
    state: {
        user: JSON.parse(localStorage.getItem('rdnaUser')) ||
            JSON.parse(sessionStorage.getItem('rdnaUser')) || {
                user_hash: btoa(navigator.userAgent),
                device_token: btoa(navigator.userAgent),
            },
        area: JSON.parse(localStorage.getItem('userArea')) || null,
        isNeedSelectArea:false
    },
    mutations: {
        setUser(state, user) {
            localStorage.removeItem('rdnaUser')
            sessionStorage.removeItem('rdnaUser') 
            state.user = { ...state.user, ...user }
            if(user.isKeepLogin) {
                localStorage.setItem('rdnaUser', JSON.stringify(state.user))
            }else {
                sessionStorage.setItem('rdnaUser', JSON.stringify(state.user))
            }
        },

        removeUser(state, user) {
            localStorage.removeItem('rdnaUser') || sessionStorage.removeItem('rdnaUser') 
            
            state.user = {
                user_hash: btoa(navigator.userAgent),
                device_token: btoa(navigator.userAgent),
            }
        },

        updateAccountStatus(state, user) {
            state.user.phone_verify = user.phone_verify
            state.user.email_verify = user.email_verify

            localStorage.removeItem('rdnaUser')
            sessionStorage.removeItem('rdnaUser') 

           if(user.isKeepLogin) {
                localStorage.setItem('rdnaUser', JSON.stringify(state.user))
            }else {
                sessionStorage.setItem('rdnaUser', JSON.stringify(state.user))
            }
        },
        
        updateUserArea(state, area) {
            state.area = area
            localStorage.setItem('userArea', JSON.stringify(state.area))
        },

        updateEmailVerification(state, isVerify) {
            state.user.email_verify = isVerify
            if(localStorage.getItem('rdnaUser')) {
                localStorage.setItem('rdnaUser', JSON.stringify(state.user))
            } else {
                sessionStorage.setItem('rdnaUser', JSON.stringify(state.user))
            }
        },

        selectAreaModal(state, show) {
            state.isNeedSelectArea = show
        }
    },
    actions: {

        register(context, payload) {
            let requestPayload = {
                phone_number: payload.phone_number,
                email: payload.email,
                password: payload.password,
                terms_accepted: payload.terms_accepted,
                device_token: payload.user_hash,
                user_hash: payload.user_hash,
                name: payload.name,
            }
            return new Request().post('register', requestPayload)
        },

        async login(context, payload) {
            let requestPayload = {
                login_data: payload.email_or_phone,
                password: payload.password,
                device_token: payload.user_hash,
                user_hash: payload.user_hash,
            }
            return new Request().post("login", requestPayload)
        },

        async logout(context, payload) {
            new Request().post("logout", payload).then(() => {
                context.commit("removeUser", payload)
                // new Response({ message: `Your Account Logged out successfully` }).displaySuccess()
                if (!this.isLoggedIn) {
                    router.go({name: Routes.HOME_ROUTE.name})
                  }
            })
        },

    },
    getters: {

        deviceToken(state) {
            return state.user?.device_token
        },
        token(state) {
            if(state.user?.token ) {
                return state.user?.token
            }
            return null
        },
        isLoggedIn(state) {
            return state.user?.token ? true : false
        },
        isVerified(state) {
            if(state.user?.email_verify) {
                return {
                    status: true,
                    verification_with:'email'
                }
            }else if(state.user?.phone_verify) {
                return {
                    status: true,
                    verification_with:'phone'
                }
            } else {
                return {status: false}
            }
        },
        userInfo(state) {
            return state.user

        },
        userArea(state) {
            return state.area
        },
        isNeedSelectArea(state) {
            return state.isNeedSelectArea
        }
    },
}
