
import en from '@/assets/i18n/en.json';
import ar from '@/assets/i18n/ar.json';

import { createI18n } from "vue-i18n";

const i18n = createI18n({
    legacy: false, // you must set `false`, to use Composition API
    locale: localStorage.getItem('lang') || 'en',
    fallbackLocale: 'en', // set fallback locale
    messages: {
        en: en,
        ar: ar,
    }    
  })
  export default i18n ;
  
